import React from "react";
import img1 from "../Image/alien1.png";
import img2 from "../Image/Body/world.gif";
import "./Station.css";
const Station = () => {
  return (
    <div className="station" id="station">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-11 mx-auto col-lg-5">
            <div className="title-white text-center py-5">ALPHAVERSE</div>

            <div className="subtitle-white pb-5">
              The Alpha Aliens metaverse will be built on NFTWorld. We have
              planned a metaverse that will have inside 3 play-to-earn minigames
              to start, but is designed to host strategy games, treasure hunts
              and other gaming experiences. Aliens will have to complete
              strategic tasks, participate in PVP and PVE games with the goal of
              earning $ALPHA prizes.
            </div>
          </div>
          <div className="col-11 col-md-8 mb-lg-0 pb-5 mx-auto col-lg-5">
            <img
              src={img2}
              alt=""
              className="img-price w-100 img-fluid"
              // data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Station;
