import React from "react";
import img1 from "../Image/alien1.png";
import img2 from "../Image/Body/game.gif";
import "./Strategy.css";
const Strategy = () => {
  return (
    <div className="strategy" id="strategy">
      <div className="container">
        <div className="row align-items-center">
          <div className="title-white text-center py-5">
            STAKING, MERGING & $ALPHA
          </div>
          <div className="col-11 mx-auto col-lg-5 pb-5">
            {/* <div className="title-white mb-3" data-aos="fade-up">
              Price
            </div> */}
            <div className="subtitle-white">
              Staking will start immediately after the reveal and will be
              rarity-based: the rarer the Alien is, the more $ALPHA tokens will
              get. In the Merging Capsule, two Aliens can be merged to get a
              rarer one, and $ALPHA can be used to unlock special traits.
              Burning mechanisms and a variety of utilities have been designed
              and will be integrated to make the $ALPHA a central feature in the
              Alpha Aliens ecosystem.Two $ALPHA airdrops are planned.
            </div>
          </div>
          <div className="col-11 mb-lg-0 pb-5 mx-auto col-lg-5">
            {/* <div className="col-11 col-lg-5 mx-auto mx-lg-0 ms-lg-auto pb-5"> */}
            <img
              src={img2}
              alt=""
              className="img-price w-100 img-fluid"
              // data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Strategy;
