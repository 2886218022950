import React from "react";
import img1 from "../Image/alien1.png";
import img2 from "../Image/Body/art.gif";
import "./FunArt.css";
const FunArt = () => {
  return (
    <div className="funart" id="funart">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-11 mx-auto col-lg-5">
            <div className="title-white text-center py-5">FUN & ART</div>
            {/* <div className="title-white mb-3" data-aos="fade-up">
              Price
            </div> */}
            <div className="subtitle-white pb-5">
              We believe that art is one of the most powerful treatments for the
              human spirit and we are the greatest supporters of total freedom
              of expression. That's why a key part of life in the Space Station
              will be dedicated to emerging creators, art competitions and
              collaborations. We want to build a community of creative minds
              that can drive the project together through art, games and fun.
            </div>
            {/* <div className="title-info mb-3 mt-5" data-aos="fade-up">
              Treasury
            </div>
            <div className="info-details mt-4 mb-3" data-aos="fade-up">
              When all the Foxes will be minted the treasury will consist of 400
              ETH, mainly splitted into 4 portions, where each portion is used
              in the 4 main branches/working groups: <br />
              <br />
              1. NFT Vault
              <br />
              2. DeFi
              <br />
              3. NFT Launchpad
              <br />
              4. Management
              <br />
              <br />
              The goal is not only to generate profits but also to gradually
              increase the capital, form new working groups and steadily expand
              our community holdings, along with managing the DAO itself with a
              specific working group operating in research and development area,
              marketing, accounting and all the other activities not
              investing-related.
              <br />
            </div>
            <div className="title-info mb-3 mt-5" data-aos="fade-up">
              Secondary Market
            </div>
            <div className="info-details mt-4 mb-5" data-aos="fade-up">
              The 50% of the secondary sales income will flow into the Treasury,
              becoming available for the working groups or for any other DAO
              purpose.
            </div> */}
          </div>
          <div className="col-11 col-md-8 col-sm-9 py-5 mx-auto col-lg-5">
            {/* <div className="col-11 col-lg-5 mx-auto mx-lg-0 ms-lg-auto pb-5"> */}
            <img
              src={img2}
              alt=""
              className="img-price w-100 img-fluid"
              // data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunArt;
