import React from "react";
import img1 from "../Image/alien1.png";
import img2 from "../Image/Body/magazine.gif";
import "./Mag.css";
const Mag = () => {
  return (
    <div className="mag" id="mag">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-11 mx-auto col-lg-5">
            {/* <div className="title-white mb-3" data-aos="fade-up">
              Price
            </div> */}
            <div className="title-white text-center py-5">ALPHA MAG</div>
            <div className="subtitle-white pb-5">
              Each week a magazine will be created with a report of everything
              Alpha Aliens have discussed in the Space Station so that everyone
              can stay updated on opportunities found, proposals made and
              competitions taking place.
            </div>
          </div>
          <div className="col-11 col-md-8 col-sm-9 mb-lg-0 py-5 mx-auto col-lg-5 order-first">
            {/* <div className="col-11 col-lg-5 mx-auto mx-lg-0 ms-lg-auto pb-5"> */}
            <img
              src={img2}
              alt=""
              className="img-price w-100 img-fluid"
              // data-aos="fade-right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mag;
