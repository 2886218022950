import React, { useState } from "react";
import { publicMint, whitelistMint } from "../../eth-setup/apis";
import header from "../Image/TB_Logo.png";
import "./Mint.css";

const Mint = () => {
  const [nftMintAmount, setNftMintAmount] = useState(1);

  return (
    <div className="mint-main" id="mint">
      <div className="container">
        <div className="title-white text-center py-5">MINT</div>
        <div className="row align-items-center">
          {/* <div className="col-lg-7 col-11 mx-auto text-center text-lg-start my-5">
            <img
              src={header}
              alt=""
              className="img-center mx-auto img-fluid"
              data-aos="fade-up"
            />
          </div> */}

          <div
            className="mint-box col-11 col-lg-5 mx-auto"
            // data-aos="fade-up"
          >
            <div className="subtitle-white mb-3 text-center">
              1st 1500 ARE FREE TO MINT (max 5) <br />
              <br />
              0.004 ETH FOR REMAINING AMOUNT
            </div>

            <div className="mint text-center mt-5">
              <div className="mint-input d-flex justify-content-center align-items-center">
                {nftMintAmount >= 0 ? (
                  <div
                    className="btn-minus mx-3"
                    onClick={() =>
                      setNftMintAmount(Math.max(1, nftMintAmount - 1))
                    }
                  >
                    <i className="fas fa-minus"></i>
                  </div>
                ) : (
                  <div
                    className="btn-minus mx-3"
                    onClick={() => setNftMintAmount(0)}
                  >
                    <i className="fas fa-minus"></i>
                  </div>
                )}

                <div className="input mx-3">{nftMintAmount}</div>

                <div
                  className="btn-plus mx-3"
                  onClick={() =>
                    setNftMintAmount(Math.min(5, nftMintAmount + 1))
                  }
                >
                  <i className="fas fa-plus"></i>
                </div>
              </div>
              <div className="mint-btn mb-3 mt-4">
                <button
                  type="button"
                  className="button-dark mint-btn-submit"
                  onClick={() => publicMint(() => {}, nftMintAmount)}
                >
                  Public Sale
                </button>
              </div>
              {/* <div className="mint-btn mb-3 mt-4">
                <button
                  type="button"
                  className="button-dark mint-btn-submit"
                  onClick={() => whitelistMint(() => {}, nftMintAmount)}
                >
                  Holder List
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
